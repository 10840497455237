import { styled } from 'Theme/stitches.config';
import BodyText from 'DesignSystem/Typography/BodyText/BodyText';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import { useEffect, useState } from 'react';
import { GetCart } from '../Cart';
import MiniCartProduct from './MiniCartProduct';
import { useAppSettings, useTranslations } from '../../../context/init-data.context';

const MiniCartContent = () => {
  const { languageRoute } = useAppSettings();
  const {
    cartLabels: { cartEmptyTitle, cartEmptyDescription },
    commonLabels: { loading: loadingText },
  } = useTranslations();
  const { cart, isLoading, isCartEmpty } = GetCart(languageRoute);
  const [cartItems, setCartItems] = useState<VariationModel[]>([]);

  useEffect(() => {
    if (cart && cart?.lineItems) {
      setCartItems(cart.lineItems);
    }
  }, [cart]);

  return (
    <MiniCartContentWrapper>
      {isLoading && <>{loadingText}</>}

      {cartItems.length > 0 && !isCartEmpty ? (
        <>
          {cartItems.map((item, index) => {
            return <MiniCartProduct item={item} key={index} />;
          })}
        </>
      ) : (
        isCartEmpty && (
          <>
            <Heading tag="h3" size={'s'} css={{ mb: '$s75' }}>
              {cartEmptyTitle}
            </Heading>
            <BodyText>{cartEmptyDescription}</BodyText>
          </>
        )
      )}
    </MiniCartContentWrapper>
  );
};

export default MiniCartContent;

const MiniCartContentWrapper = styled('div', {
  mt: 6,
});
