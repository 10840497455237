import KexIconLoader from 'DesignSystem/Icons/KexIconLoader';
import { styled } from 'Theme/stitches.config';
import { animation, timings } from 'DesignSystem/Animations/animation';
import { useEffect, useRef } from 'react';


import { useTranslations } from '../../../context/init-data.context';

const SubtractIcon = KexIconLoader('Subtract');
const AddIcon = KexIconLoader('Cross');

export type QuantityType = number | undefined;

type PropTypes = {
  code: string;
  maxQuantity: QuantityType;
  minQuantity: QuantityType;
  currentQuantity: QuantityType;
  handleStepperIncrement: (code: string) => void;
  handleStepperDecrement: (code: string) => void;
  handleInputChange: (quantity: QuantityType) => void;
  handleOnBlur?: () => void;
  disabled?: boolean;
  disabledMin?: boolean;
};

const INITIAL_WIDTH = 4;
function QuantityStepper({
  code,
  maxQuantity,
  minQuantity,
  currentQuantity,
  disabled,
  disabledMin,
  handleStepperIncrement,
  handleStepperDecrement,
  handleInputChange,
  handleOnBlur,
}: PropTypes) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  function changeInputWidth(quantity: QuantityType) {
    if (!inputRef.current) return;

    const calcWidth = quantity
      ? quantity.toString().length + INITIAL_WIDTH + 'ch'
      : '';

    inputRef.current.style.width = calcWidth;
  }

  const {
    productLabels: { quantity },
    cartLabels: { increaseQuantity, decreaseQuantity },
  } = useTranslations();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const numberOnlyRegex = /^[0-9]*$/;

    if (numberOnlyRegex.test(e.target.value)) {
      const numberEventValue = e.target.value
        ? parseInt(e.target.value)
        : undefined;
      handleInputChange(numberEventValue);
    }
  }

  useEffect(() => {
    changeInputWidth(currentQuantity);
  }, [currentQuantity]);

  const hasReachedMaxQuantity = currentQuantity === maxQuantity;
  const hasReachedMinQuantity = currentQuantity === minQuantity;

  return (
    <QuantityWrapper>
      <IconWrapper
        onClick={async () => {
          await handleStepperDecrement(code);
        }}
        disabled={hasReachedMinQuantity || disabled || disabledMin}
        aria-label={decreaseQuantity}
        tabIndex={-1}
      >
        <SubtractIcon color={'primary'} size={'s'} />
      </IconWrapper>
      <CurrentQuantityWrapper
        ref={inputRef}
        value={currentQuantity || ''}
        onChange={handleChange}
        onBlur={handleOnBlur}
        min={minQuantity}
        max={maxQuantity}
        type="number"
        disabled={disabled}
        aria-label={quantity}
      ></CurrentQuantityWrapper>
      <IconWrapper
        onClick={async () => {
          await handleStepperIncrement(code);
        }}
        disabled={hasReachedMaxQuantity || disabled}
        aria-label={increaseQuantity}
        tabIndex={-1}
      >
        <AddIcon color={'primary'} size={'s'} />
      </IconWrapper>
    </QuantityWrapper>
  );
}

const BaseQuantityStyle = {
  backgroundColor: 'transparent',
  color: '$secondary2',
  lineHeight: '$lh125',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid transparent',
  height: '100%',
  variants: {
    disabled: {
      true: {
        opacity: 0.6,
        pointerEvents: 'none',
      },
    },
  },
};

const QuantityWrapper = styled('div', {
  display: 'flex',
  border: '1px solid $staticBorderDefault',
  height: 30,
  backgroundColor: '$white',
  'input[type=number]': {
    ':focus:not(:focus-visible)': {
      outline: 'initial',
    },
  },
});

const CurrentQuantityWrapper = styled('input', {
  ...BaseQuantityStyle,
  w: '34px !important',
  fontSize: '$fontSize50',
  textAlign: 'center',
  color: '$onSurface',

  '&::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '&::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '&[type=number]': {
    MozAppearance: 'textfield',
  },
  '&::selection': {
    backgroundColor: 'rgba(132, 186, 236, 0.5)',
    color: 'initial',
  },
});

const IconWrapper = styled('button', {
  ...BaseQuantityStyle,
  transition: `all ${timings.oneFifth} ${animation.timingFn}`,
  cursor: 'pointer',
  px: 2,
  '&:hover': {
    backgroundColor: '$interactiveSubtleBackgroundHover',
  },
  '&:active': {
    backgroundColor: '$interactiveSubtleBackgroundHover',
  },
});

export default QuantityStepper;
